exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-ai-for-talent-js": () => import("./../../../src/pages/ai-for-talent.js" /* webpackChunkName: "component---src-pages-ai-for-talent-js" */),
  "component---src-pages-building-an-ai-product-js": () => import("./../../../src/pages/building-an-ai-product.js" /* webpackChunkName: "component---src-pages-building-an-ai-product-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-discover-js": () => import("./../../../src/pages/discover.js" /* webpackChunkName: "component---src-pages-discover-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-fairness-js": () => import("./../../../src/pages/fairness.js" /* webpackChunkName: "component---src-pages-fairness-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insights-js": () => import("./../../../src/pages/insights.js" /* webpackChunkName: "component---src-pages-insights-js" */),
  "component---src-pages-mlops-maturity-scan-js": () => import("./../../../src/pages/mlops-maturity-scan.js" /* webpackChunkName: "component---src-pages-mlops-maturity-scan-js" */),
  "component---src-pages-our-clients-js": () => import("./../../../src/pages/our-clients.js" /* webpackChunkName: "component---src-pages-our-clients-js" */),
  "component---src-pages-our-vision-js": () => import("./../../../src/pages/our-vision.js" /* webpackChunkName: "component---src-pages-our-vision-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-signature-maker-js": () => import("./../../../src/pages/signature-maker.js" /* webpackChunkName: "component---src-pages-signature-maker-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-tech-stack-js": () => import("./../../../src/pages/tech-stack.js" /* webpackChunkName: "component---src-pages-tech-stack-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-pages-training-js": () => import("./../../../src/pages/training.js" /* webpackChunkName: "component---src-pages-training-js" */),
  "component---src-pages-what-is-ai-js": () => import("./../../../src/pages/what-is-ai.js" /* webpackChunkName: "component---src-pages-what-is-ai-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-case-study-template-js": () => import("./../../../src/templates/case-study-template.js" /* webpackChunkName: "component---src-templates-case-study-template-js" */),
  "component---src-templates-event-template-js": () => import("./../../../src/templates/event-template.js" /* webpackChunkName: "component---src-templates-event-template-js" */)
}

